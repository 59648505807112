import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './home/home.component';
import { ProductGalleryComponent } from './product-gallery/product-gallery.component';
import { FixedProductComponent } from './fixed-product/fixed-product.component';
import { DynamicProductComponent } from './dynamic-product/dynamic-product.component';
import { CartComponent } from './cart/cart.component';
import { MyOrdersComponent } from './my-orders/my-orders.component';

const productTypes = [
  { type: 'cloths', isFixed: false },
  { type: 'accessories', isFixed: true },
  { type: 'models', isFixed: false },
  { type: 'unclassifiedProducts', isFixed: true }
]

const routes: Routes = [
  {
    path: '',
    component: HomeComponent
  },
  ...productTypes.map(p => {
    return {
      path: `${p.type}`,
      component: ProductGalleryComponent,
      data: { productType: p.type }
    }
  }),
  ...productTypes.map(p => {
    return {
      path: `${p.type}/:id`,
      component: p.isFixed ? FixedProductComponent : DynamicProductComponent,
      data: { productType: p.type }
    }
  }),
  {
    path: 'cart',
    component: CartComponent
  },
  {
    path: 'my-orders',
    component: MyOrdersComponent
  },
  {
    path: '**',
    redirectTo: ''
  }
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
