import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { APIService } from '../services/apiService/api.service';
import { StateService } from '../services/stateService/state.service';
import { StringsService } from '../services/stringsService/strings.service';

import OrderItem from '../../../../common/dataType/orderItem'
import { AlertService } from '../services/alertService/alert.service';

@Component({
  selector: 'app-fixed-product',
  templateUrl: './dynamic-product.component.html',
  styleUrls: ['./dynamic-product.component.css']
})
export class DynamicProductComponent implements OnInit {
  productType
  productId
  isLoading = true
  isError = false
  product
  quantity = 1
  selectedColor
  companion
  companions
  person

  constructor(
    public strings: StringsService,
    public state: StateService,
    private route: ActivatedRoute,
    private api: APIService,
    private alert: AlertService
  ) { }

  ngOnInit() {
    this.route.paramMap.subscribe(async paramMap => {
      this.productId = paramMap.get('id')
      this.productType = this.route.snapshot.data.productType
      await this.loadProduct()
      this.isLoading = false
      this.companions = this.productType === 'cloths' ? this.state.selectedModels : this.state.selectedCloths
    })
  }

  async loadProduct() {
    if (this.state[this.productType].some(p => p.id === this.productId)) {
      this.product = this.state[this.productType].find(p => p.id === this.productId)
    } else {
      const { isRequestSuccessful, item } = await this.api.getItem(this.productType, this.productId)
      if (!isRequestSuccessful) this.isError = true
      else this.product = item
    }
    this.selectedColor = this.product.availableColors?.length ? this.product.availableColors[0] : null
  }

  chooseProduct() {
    if (this.productType === 'cloths') {
      if (!this.state.selectedCloths.find(i => i.id === this.product.id))
        this.state.selectedCloths.push(this.product)
    } else {
      if (!this.state.selectedModels.find(i => i.id === this.product.id))
        this.state.selectedModels.push(this.product)
    }
    this.alert.info(this.strings.ADDED_TO_SELECTED[this.productType])
  }

  selectCompanionById(id) {
    this.companion = this.companions.find(c => c.id === id)
    this.selectedColor = this.companion.availableColors?.length ? this.companion.availableColors[0] : this.selectedColor
  }

  selectPersonById(id) {
    this.person = this.state.user.people.find(p => p.id === id)
  }

  addToCart() {
    const itemA = this.productType === 'cloths' ? this.companion : this.product
    const itemB = this.productType === 'cloths' ? this.product : this.companion
    const newItem = new OrderItem('dynamic', this.quantity, itemA, this.selectedColor, itemB, this.person)
    if (this.state.cart.some(i => i.hasTheSameItemOf(newItem)))
      this.state.cart.find(i => i.hasTheSameItemOf(newItem)).quantity += newItem.quantity
    else this.state.cart.push(newItem)
    this.alert.info(this.strings.ADDED_TO_CART)
  }
}
