import { Component, Input } from '@angular/core';
import { StringsService } from '../services/stringsService/strings.service';

@Component({
  selector: 'app-product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.css']
})
export class ProductCardComponent {

  @Input() product: any

  constructor(public strings: StringsService) { }

  round(n){
    return Math.round(n)
  }
}
