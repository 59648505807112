import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AlertService } from './services/alertService/alert.service'
import { StringsService } from './services/stringsService/strings.service'
import { APIService } from './services/apiService/api.service'
import { StateService } from './services/stateService/state.service'
import { AuthService } from './services/authService/auth.service'
import { IdService } from './services/idService/id.service'

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { ProductGalleryComponent } from './product-gallery/product-gallery.component';
import { ProductCardComponent } from './product-card/product-card.component';
import { FixedProductComponent } from './fixed-product/fixed-product.component';
import { DynamicProductComponent } from './dynamic-product/dynamic-product.component';
import { CartComponent } from './cart/cart.component';
import { MyOrdersComponent } from './my-orders/my-orders.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ProductGalleryComponent,
    ProductCardComponent,
    FixedProductComponent,
    DynamicProductComponent,
    CartComponent,
    MyOrdersComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule
  ],
  providers: [AlertService, StringsService, APIService, StateService, AuthService, IdService],
  bootstrap: [AppComponent]
})
export class AppModule { }
