<div class="fullscreen" *ngIf="isLoading && !isError">
    <p>{{strings.LOADING}}</p>
</div>

<div class="fullscreen" *ngIf="!isLoading && isError">
    <p>{{strings.REQUEST_ERROR}}</p>
</div>

<div class="flex" *ngIf="!isLoading && !isError">
    <div>
        <img [src]="product.imageUrl">
    </div>
    <div>
        <p><strong>{{strings.NAME}}:</strong> {{product.name}}</p>
        <p *ngIf="product.countryOfOrigin">
            <strong>{{strings.COUNTRY_OF_ORIGIN}}:</strong> {{product.countryOfOrigin}}
        </p>
        <p *ngIf="product.size"><strong>{{strings.SIZE}}:</strong> {{product.size}}</p>
        <p *ngIf="product.rating"><strong>{{strings.RATING}}:</strong> {{product.rating}}<img src="../../assets/icons/ic_star.svg"> {{strings.OUT_OF_10}}</p>
        <div *ngIf="product.availableColors">
            <p><strong>{{strings.AVAILABLE_COLORS}}: </strong></p>
            <div class="colors">
                <span *ngFor="let color of product.availableColors; index as i;" class="color" [style.backgroundColor]="color" [title]="color" (click)="selectedColor = color">
                    <span *ngIf="selectedColor !== color">&nbsp;</span>
                    <span *ngIf="selectedColor === color">✔</span>
                </span>
            </div>
        </div>
        <p><strong>{{strings.PRICE}}:</strong> {{product.price}} {{strings.SAUDI_RIYAL}} <span *ngIf="product.priceBeforeOffer">{{strings.INSTEAD}} {{product.priceBeforeOffer}} {{strings.SAUDI_RIYAL}}</span></p>
        <div *ngIf="product.description">
            <p><strong>{{strings.DESCRIPTION}}:</strong></p>
            <p>{{product.description}}</p>
        </div>
        <div id="add-to-cart">
            <div>
                <button (click)="quantity=quantity > 1 ? quantity-1 : quantity">-</button>
                <span>{{quantity}}</span>
                <button (click)="quantity=quantity+1">+</button>
            </div>
            <button id="add" (click)="addToCart()">{{strings.ADD_TO_CART}} <img src="../../assets/icons/ic_cart_white.svg"></button>
        </div>
        <div id="cards">
            <div class="card">
                <div class="header">
                    <img src="../../assets/icons/ic_delivery_truck.svg">
                    <p class="title">{{strings.FREE_DELIVERY_TITLE}}</p>
                </div>
                <p class="description">{{strings.FREE_DELIVERY_DESC}}</p>
            </div>
            <div class="card">
                <div class="header">
                    <img src="../../assets/icons/ic_clock.svg">
                    <p class="title">{{strings.FAST_DELIVERY_TITLE}}</p>
                </div>
                <p class="description">{{strings.FAST_DELIVERY_DESC}}</p>
            </div>
            <div class="card">
                <div class="header">
                    <img src="../../assets/icons/ic_cash_pay.svg">
                    <p>{{strings.PAY_ON_DELIVERY_TITLE}}</p>
                </div>
                <p class="description">{{strings.PAY_ON_DELIVERY_DESC}}</p>
            </div>
        </div>
    </div>
</div>