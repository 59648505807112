<div class="top-section">
    <img [src]="product.imageUrl">
    <p class="offer" *ngIf="product.priceBeforeOffer > product.price">
        {{round((product.priceBeforeOffer - product.price) / product.priceBeforeOffer * 100)}}%
    </p>
</div>
<div class="bottom-section">
    <div>
        <p>{{product.name || product.code}}</p>
        <p class="price">
            <del *ngIf="product.priceBeforeOffer > product.price">
                {{product.priceBeforeOffer}} {{strings.SAUDI_RIYAL}}
            </del>
            <span>{{product.price}} {{strings.SAUDI_RIYAL}}</span>
        </p>
    </div>
    <img src="../../assets/icons/ic_cart_grey.svg">
</div>