import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService } from '../services/alertService/alert.service';
import { APIService } from '../services/apiService/api.service';
import { StateService } from '../services/stateService/state.service';
import { StringsService } from '../services/stringsService/strings.service';

@Component({
  selector: 'app-product-gallery',
  templateUrl: './product-gallery.component.html',
  styleUrls: ['./product-gallery.component.css']
})
export class ProductGalleryComponent implements OnInit {
  isLoading = true
  productType
  products
  minPrice
  maxPrice
  selectedColors = new Set()

  constructor(
    public state: StateService,
    public strings: StringsService,
    public router: Router,
    private route: ActivatedRoute,
    private api: APIService,
    private alert: AlertService
  ) { }

  ngOnInit() {
    this.route.paramMap.subscribe(async paramMap => {
      this.productType = this.route.snapshot.data.productType
      this.loadProducts()
    })
  }

  async loadProducts() {
    if (!this.state[this.productType].length) {
      const { isRequestSuccessful, items } = await this.api.getCollection(this.productType)
      if (!isRequestSuccessful) {
        this.alert.error(this.strings.REQUEST_ERROR)
        this.router.navigateByUrl('')
      } else {
        this.state[this.productType] = items
      }
    }
    this.isLoading = false
    this.products = this.state[this.productType]
  }

  get filteredProducts() {
    let filteredProducts = this.products
    const isMinValid = this.isPositiveNumber(this.minPrice)
    const isMaxValid = this.isPositiveNumber(this.maxPrice)
    if (isMinValid && isMaxValid && this.minPrice <= this.maxPrice)
      filteredProducts = filteredProducts.filter(
        product => product.price >= this.minPrice && product.price <= this.maxPrice
      )
    else if (isMinValid) filteredProducts = filteredProducts.filter(product => product.price >= this.minPrice)
    else if (isMaxValid) filteredProducts = filteredProducts.filter(product => product.price <= this.maxPrice)
    if (this.selectedColors.size) {
      filteredProducts = filteredProducts.filter(product =>
        this.intersection(this.selectedColors, new Set(product.availableColors.map(c => c.toLowerCase()))).size
      )
    }
    return filteredProducts
  }

  private intersection(setA, setB) {
    let intersection = new Set()
    for (const element of setB) {
      if (setA.has(element)) {
        intersection.add(element)
      }
    }
    return intersection
  }

  get colors() {
    return [...new Set(this.products.map(p => p.availableColors).flat().map(c => c.toLowerCase()))]
  }

  round(n) {
    n = parseInt(n)
    return this.isPositiveNumber(n) ? Math.round(n) : null
  }

  isPositiveNumber(n) {
    return typeof n == 'number' && Number.isFinite(n) && n >= 0
  }

  toggleColor(color) {
    if (this.selectedColors.has(color)) this.selectedColors.delete(color)
    else this.selectedColors.add(color)
  }
}
