<div id="no-orders" *ngIf="!orders?.length">
    <p>{{strings.NO_ORDERS}}</p>
</div>

<div *ngIf="orders?.length">
    <div class="order" *ngFor="let order of orders; index as i">
        <h1>{{strings.ORDER}} #{{orders.length - i}}</h1>
        <p class="large"><strong>{{strings.ORDER_STATUS}}:</strong> {{order.isDelivered ? strings.DELIVERED : strings.PENDING}}</p>
        <p class="large"><strong>{{strings.ORDER_DATE}}:</strong> {{arabicDate(order.orderDate)}}</p>
        <p class="large"><strong>{{strings.DELIVERY_DATE}}:</strong> {{arabicDate(order.deliveryDate)}}</p>
        <p class="large"><strong>{{strings.TOTAL_PRICE}}:</strong> {{order.totalPrice}} {{strings.SAUDI_RIYAL}}</p>
        <p class="large"><strong>{{strings.ITEMS}}:</strong></p>
        <div id="items">
            <hr>
            <strong>{{strings.PRODUCT}}</strong>
            <strong>{{strings.QUANTITY}}</strong>
            <strong>{{strings.PRICE}}</strong>
            <strong>{{strings.TOTAL}}</strong>
            <hr>
            <ng-container *ngFor="let item of order.items; index as j">
                <div class="info">
                    <img [src]="item.itemA.imageUrl">
                    <div>
                        <p><strong>{{item.itemA.name || item.itemA.code}}</strong></p>
                        <p *ngIf="item.itemB?.type">{{item.itemB.type}}</p>
                        <p *ngIf="item.person">{{strings.RELATIONSHIPS[item.person.relationship]}} - {{item.person.name}}</p>
                        <div class="color-container" *ngIf="item.color"><span>{{strings.COLOR}}</span><span class="color" [style.backgroundColor]="item.color">&nbsp;</span></div>
                    </div>
                </div>
                <span>{{item.quantity}}</span>
                <span>{{getItemPrice(item)}} {{strings.SAUDI_RIYAL}}</span>
                <span>{{getItemTotalPrice(item)}} {{strings.SAUDI_RIYAL}}</span>
                <hr>
            </ng-container>
        </div>
        <hr *ngIf="i < orders.length - 1">
    </div>
</div>