<div id="host">
    <div id="empty-cart" *ngIf="!state.cart?.length">
        <img src="../../assets/icons/ic_empty_cart.svg">
        <p>{{strings.EMPTY_CART}}</p>
    </div>

    <div id="cart" *ngIf="state.cart?.length">
        <div id="products">
            <strong>{{strings.PRODUCT}}</strong>
            <strong>{{strings.QUANTITY}}</strong>
            <strong>{{strings.PRICE}}</strong>
            <strong>{{strings.TOTAL}}</strong>
            <hr>
            <ng-container *ngFor="let item of state.cart; index as i">
                <div class="info">
                    <img [src]="item.itemA.imageUrl">
                    <div>
                        <p><strong>{{item.itemA.name || item.itemA.code}}</strong></p>
                        <p *ngIf="item.itemB?.type">{{item.itemB.type}}</p>
                        <p *ngIf="item.person">{{strings.RELATIONSHIPS[item.person.relationship]}} - {{item.person.name}}</p>
                        <div class="color-container" *ngIf="item.color"><span>{{strings.COLOR}}</span><span class="color" [style.backgroundColor]="item.color">&nbsp;</span></div>
                    </div>
                </div>
                <div class="quantity">
                    <button (click)="item.quantity=item.quantity > 1 ? item.quantity-1 : item.quantity">-</button>
                    <span>{{item.quantity}}</span>
                    <button (click)="item.quantity=item.quantity+1">+</button>
                </div>
                <span>{{getItemPrice(item)}} {{strings.SAUDI_RIYAL}}</span>
                <div class="close-container">
                    <span>{{getItemTotalPrice(item)}} {{strings.SAUDI_RIYAL}}</span>
                    <div class="close" (click)="deleteItem(i)">&times;</div>
                </div>
                <hr>
            </ng-container>
        </div>
        <div id="bill">
            <div id="location">
                <p>{{strings.DELIVERY_LOCATION}}</p>
                <hr>
                <select (input)="isDeliveryToYanbo3=$event.target.value === 'true'">
                    <option [value]="true">{{strings.YANBO3}}</option>
                    <option *ngIf="noDynamic" [value]="false">{{strings.OTHER}}</option>
                </select>
                <input *ngIf="!isDeliveryToYanbo3" type="text" (input)="deliveryLocation=$event.target.value" [placeholder]="strings.ENTER_DELIVERY_LOCATION">
            </div>
            <div id="overview">
                <p>{{strings.OVERVIEW}}</p>
                <hr>
                <div class="details">
                    <ng-container *ngFor="let item of state.cart; index as i">
                        <p><span>{{item.itemA.name || item.itemA.code}}</span> &times; <span>{{item.quantity}}</span></p>
                        <p>{{getItemTotalPrice(item)}} {{strings.SAUDI_RIYAL}}</p>
                    </ng-container>
                    <p>{{strings.DELIVERY_PRICE}}</p>
                    <p>
                        <span *ngIf="deliveryPrice>0">{{deliveryPrice}} {{strings.SAUDI_RIYAL}}</span>
                        <span *ngIf="deliveryPrice<=0">{{strings.FREE}}</span>
                    </p>
                    <p *ngIf="!noDynamic">{{strings.HAND_PRICE}}</p>
                    <p *ngIf="!noDynamic">
                        <span *ngIf="handPrice>0">{{handPrice}} {{strings.SAUDI_RIYAL}}</span>
                        <span *ngIf="handPrice<=0">{{strings.FREE}}</span>
                    </p>
                </div>
                <hr>
                <div id="total" class="details">
                    <p>{{strings.TOTAL_PRICE}}</p>
                    <p>
                        <span *ngIf="totalPrice>0">{{totalPrice}} {{strings.SAUDI_RIYAL}}</span>
                        <span *ngIf="totalPrice<=0">{{strings.FREE}}</span>
                    </p>
                    <p id="date">{{strings.DELIVERY_IN}} {{deliveryTime}} {{deliveryTime <= 10? strings.DAYS : strings.DAY}}</p>
                </div>
                <div #paypal></div>
            </div>
        </div>
    </div>

    <div id="loading" *ngIf="isLoading">
        <p>{{strings.LOADING}}</p>
    </div>
</div>