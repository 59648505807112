import { Injectable } from '@angular/core';
import { APIService } from '../apiService/api.service';
import firebase from '../firebaseService/firebase.service'

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private _userId
  private _userPhoneNumber
  private _isUserAuthenticated = false
  private _AuthenticationToken = ''
  private _callbacks = []

  constructor() {
    firebase.auth().languageCode = 'ar'
    firebase.auth().onAuthStateChanged(async user => {
      this._isUserAuthenticated = Boolean(user)
      this._AuthenticationToken = this._isUserAuthenticated ? await user.getIdToken() : ''
      this._userId = user?.uid
      this._userPhoneNumber = user?.phoneNumber
      this.notifySubscribers()
    })
  }

  private notifySubscribers() {
    for (const callback of this._callbacks) {
      callback(this._isUserAuthenticated)
    }
  }

  onAuthStateChanged(callback: (newState: boolean) => void) {
    this._callbacks.push(callback)
  }

  get isUserAuthenticated() {
    return this._isUserAuthenticated
  }

  get authorizationToken() {
    return this._AuthenticationToken
  }

  get RecaptchaVerifier() {
    return firebase.auth.RecaptchaVerifier
  }

  get userId() {
    return this._userId
  }

  get userPhoneNumber() {
    return this._userPhoneNumber
  }

  async signIn(phone: string, appVerifier: firebase.auth.ApplicationVerifier) {
    return firebase.auth().signInWithPhoneNumber(phone, appVerifier)
  }

  async signOut() {
    try {
      await firebase.auth().signOut()
      return true
    } catch (e) {
      console.error(e)
      return false
    }
  }
}
