import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import User from '../../../common/dataType/user';
import { AlertService } from './services/alertService/alert.service';
import { APIService } from './services/apiService/api.service';
import { AuthService } from './services/authService/auth.service';
import { StateService } from './services/stateService/state.service';
import { StringsService } from './services/stringsService/strings.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  readonly title = 'sixteen-angles'
  readonly navRoutes = [
    new NavRoute('', this.strings.HOME),
    new NavRoute('models', this.strings.MODELS),
    new NavRoute('cloths', this.strings.CLOTHS),
    new NavRoute('accessories', this.strings.ACCESSORIES),
    new NavRoute('unclassifiedProducts', this.strings.OTHER_PRODUCTS)
  ]
  private recaptchaVerifier
  private recaptchaWidgetId
  private confirmationResult
  phoneNumber = ""
  code = ""
  showSignIn = false
  showEnterPhone = false
  showEnterCode = false
  showActions = false
  showViewPeople = false
  showPersonViewer = false
  personId = 0

  constructor(
    public router: Router,
    public state: StateService,
    public alert: AlertService,
    public auth: AuthService,
    public strings: StringsService,
    private api: APIService
  ) { }

  ngOnInit() {
    this.auth.onAuthStateChanged(async (isUserAuthenticated: boolean) => {
      if (isUserAuthenticated) {
        await this.getUserFromDatabase(this.auth.userId)
      }
    })
  }

  onAccountButtonClicked() {
    if (!this.auth.isUserAuthenticated) {
      this.showSignIn = true
      this.showEnterPhone = true
      setTimeout(this.initRecaptcha.bind(this), 0)
    } else {
      this.showActions = true
    }
  }

  async initRecaptcha() {
    this.recaptchaVerifier = new this.auth.RecaptchaVerifier('btn-sign', {
      size: 'invisible',
      callback: (response) => { this.onSignInSubmit() }
    })
    this.recaptchaWidgetId = await this.recaptchaVerifier.render()
  }

  async onSignInSubmit() {
    try {
      this.phoneNumber = `+966${this.phoneNumber}`
      this.confirmationResult = await this.auth.signIn(this.phoneNumber, this.recaptchaVerifier)
      this.showEnterPhone = false
      this.showEnterCode = true
    } catch (e) {
      console.error(e)
      this.alert.error(this.strings.ERROR_SMS_NOT_SENT)
      globalThis.grecaptcha.reset(this.recaptchaWidgetId)
    }
  }

  async confirmCode() {
    try {
      await this.confirmationResult.confirm(this.code)
      this.showSignIn = false
      this.showEnterCode = false
    } catch (e) {
      console.error(e)
      this.alert.error(this.strings.ERROR_CODE)
    }
  }

  async getUserFromDatabase(userId) {
    const { isRequestSuccessful, item } = await this.api.getItem('users', userId)
    if (!isRequestSuccessful && this.auth.isUserAuthenticated) {
      this.state.user = {
        id: this.auth.userId,
        phoneNumber: this.auth.userPhoneNumber
      }
      this.api.putItem('users', userId, this.state.user)
    } else {
      this.state.user = item
    }
  }

  get person() {
    return this.state.user.people.find(user => user.id === this.personId)
  }

  parseInt(n) {
    return parseInt(n)
  }

  viewPerson(id) {
    this.personId = id
    this.showViewPeople = false
    this.showPersonViewer = true
  }

  closePersonViewer(){
    this.showViewPeople = true
    this.showPersonViewer = false
  }
}

class NavRoute {
  constructor(
    public readonly route: string,
    public readonly displayText: string
  ) { }
}