<header>
	<div id="info">
		<div id="physical">
			<span><img src="../assets/icons/ic_location.svg"><span>{{strings.ADDRESS}}</span></span>
			<span><img src="../assets/icons/ic_phone.svg"><span dir="ltr">{{strings.PHONE}}</span></span>
		</div>
		<div id="social-media">
			<a href="https://www.facebook.com/profile.php?id=100020143822339" target="_blank" rel="noopener norefer">
				<img src="../assets/icons/ic_facebook.svg">
			</a>
			<a href="https://instagram.com/16angles?igshid=1mwu9uj0zq6ur" target="_blank" rel="noopener norefer">
				<img src="../assets/icons/ic_instagram.svg">
			</a>
		</div>
	</div>
	<div id="logo">
		<img src="../assets/logo.svg">
	</div>
	<nav>
		<div id="menus">
			<span *ngFor="let navRoute of navRoutes; index as i;" (click)="router.navigateByUrl(navRoute.route)">
				{{navRoute.displayText}}
			</span>
		</div>
		<div id="icons">
			<a class="badge" [attr.data-badge]="state.cart.length" (click)="router.navigateByUrl('cart')"><img src="../assets/icons/ic_cart_white.svg"></a>
			<a (click)="onAccountButtonClicked()"><img src="../assets/icons/ic_profile.svg"></a>
		</div>
	</nav>
</header>

<div class="router-outlet">
	<router-outlet></router-outlet>
</div>

<footer>
	<div>
		<img src="../assets/logo.svg">
	</div>
	<div>
		<div class="underlined-text">
			<h2>{{strings.WHO_WE_ARE}}</h2>
			<hr>
		</div>
		<p>{{strings.WHO_WE_ARE_DISC}}</p>
	</div>
	<div>
		<div class="underlined-text">
			<h2>{{strings.IMPORTANT_LINKS}}</h2>
			<hr>
		</div>
		<p *ngFor="let navRoute of navRoutes; index as i;" (click)="router.navigateByUrl(navRoute.route)">
			<span class="footer-link">{{navRoute.displayText}}</span>
		</p>
	</div>
	<div id="contact-us">
		<div class="underlined-text">
			<h2>{{strings.CONTACT_US}}</h2>
			<hr>
		</div>
		<div class="social-links">
			<div>
				<p><a href="https://www.facebook.com/profile.php?id=100020143822339" target="_blank" rel="noopener norefer"><img src="../assets/icons/ic_facebook.svg"></a></p>
				<p><a href="https://instagram.com/16angles?igshid=1mwu9uj0zq6ur" target="_blank" rel="noopener norefer"><img src="../assets/icons/ic_instagram.svg"></a></p>
				<p><a><img src="../assets/icons/ic_phone.svg"></a></p>
			</div>
			<div>
				<p><a href="https://www.facebook.com/profile.php?id=100020143822339" target="_blank" rel="noopener norefer">{{strings.FACEBOOK}}</a></p>
				<p><a href="https://instagram.com/16angles?igshid=1mwu9uj0zq6ur" target="_blank" rel="noopener norefer">{{strings.INSTAGRAM}}</a></p>
				<p dir=ltr><a>{{strings.PHONE}}</a></p>
			</div>
		</div>
	</div>
</footer>

<div id="phone-number-form" class="fullscreen-dialog-container" *ngIf="showSignIn">
	<div class="fullscreen-dialog">
		<div class="logo">
			<img src="../assets/logo.svg">
			<img src="../assets/sign_in_and_sign_out_dialog_image.svg">
		</div>
		<div class="form" *ngIf="showEnterPhone">
			<h2>{{strings.SIGN_WITH_US}}</h2>
			<div>
				<label for="phone">{{strings.PHONE_NUMBER}}</label>
				<div dir="ltr" class="input-container">
					<img src="../assets/icons/ic_saudi_arabia.svg">
					<p>+966</p>
					<input type="tel" id="phone" name="phone" pattern="[0-9]*" required (input)="phoneNumber = $event.target.value">
				</div>
			</div>
			<div><button id="btn-sign">{{strings.SIGN}}</button></div>
		</div>
		<div class="form" *ngIf="showEnterCode">
			<h2>{{strings.SIGN_WITH_US}}</h2>
			<div>
				<label for="code">{{strings.CODE_SENT}} <span dir="ltr">{{phoneNumber}}</span></label>
				<div dir="ltr" class="input-container">
					<input type="text" id="code" name="code" pattern="[0-9]*" required (input)="code = $event.target.value">
				</div>
			</div>
			<button id="btn-code" (click)="confirmCode()">{{strings.CONFIRM}}</button>
		</div>
		<span class="close" (click)="showSignIn = false; showEnterPhone = true; showEnterCode = false">&times;</span>
	</div>
</div>

<div id="actions" class="fullscreen-dialog-container" *ngIf="showActions">
	<div class="fullscreen-dialog">
		<p>{{strings.PHONE_NUMBER}}: <span dir=ltr>{{state.user.phoneNumber}}</span></p>
		<button *ngIf="state.user?.people?.length" (click)="showViewPeople = true; showActions = false">{{strings.VIEW_PEOPLE}}</button>
		<button (click)="router.navigateByUrl('my-orders'); showActions = false">{{strings.MY_ORDERS}}</button>
		<button (click)="auth.signOut(); state.user = undefined; showActions = false">{{strings.SIGN_OUT}}</button>
		<span class="close" (click)="showActions = false">&times;</span>
	</div>
</div>

<div id="view-people" class="fullscreen-dialog-container" *ngIf="showViewPeople">
	<div class="fullscreen-dialog">
		<hr style="width: 100%;">
		<div *ngFor="let person of state.user.people; index as i;">
			<div class="person">
				<p>{{strings.RELATIONSHIPS[person.relationship]}}</p>
				<p>{{person.name}}</p>
				<p class="person-action" (click)="viewPerson(person.id)">{{strings.MORE}}</p>
			</div>
			<hr>
		</div>
		<span class="close" (click)="showViewPeople = false">&times;</span>
	</div>
</div>

<div id="person-viewer" class="fullscreen-dialog-container" *ngIf="showPersonViewer">
	<div class="fullscreen-dialog">
		<p><strong>{{strings.NAME}}:</strong> {{person.name}}</p>
		<p><strong>{{strings.AGE}}:</strong> {{person.age}}</p>
		<p><strong>{{strings.RELATIONSHIP}}:</strong> {{strings.RELATIONSHIPS[person.relationship]}}</p>
		<p><strong>{{strings.HEIGHT}}:</strong> {{person.height}}</p>
		<p><strong>{{strings.SHOULDER_LENGTH}}:</strong> {{person.shoulderLength}} {{strings.INCH}}</p>
		<p><strong>{{strings.SLEEVE_LENGTH}}:</strong> {{person.sleeveLength}} {{strings.INCH}}</p>
		<p><strong>{{strings.CHEST_LENGTH}}:</strong> {{person.chestLength}} {{strings.INCH}}</p>
		<p><strong>{{strings.NECK_LENGTH}}:</strong> {{person.neckLength}} {{strings.CM}}</p>
		<p><strong>{{strings.HAND_SIZE}}:</strong> {{person.handSize}} {{strings.INCH}}</p>
		<p><strong>{{strings.KABAK_LENGTH}}:</strong> {{person.kabakLength}} {{strings.INCH}}</p>
		<p><strong>{{strings.DOWN_LENGTH}}:</strong> {{person.downLength}} {{strings.INCH}}</p>
		<span class="close" (click)="closePersonViewer()">&times;</span>
	</div>
</div>

<div id="alerts">
	<div [class]="['alert', a.clazz].join(' ')" *ngFor="let a of alert.alerts; index as i;" (animationend)="alert.close(a.id)">
		{{a.msg}} <span class="close" (click)="alert.close(a.id)">&times;</span>
	</div>
</div>