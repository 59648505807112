<div class="fullscreen" *ngIf="isLoading && !isError">
    <p>{{strings.LOADING}}</p>
</div>

<div class="fullscreen" *ngIf="!isLoading && isError">
    <p>{{strings.REQUEST_ERROR}}</p>
</div>

<div class="flex" *ngIf="!isLoading && !isError">
    <div>
        <img [src]="product.imageUrl">
    </div>
    <div>
        <p *ngIf="product.name"><strong>{{strings.NAME}}:</strong> {{product.name}}</p>
        <p *ngIf="product.code"><strong>{{strings.CODE}}:</strong> {{product.code}}</p>
        <p *ngIf="product.type"><strong>{{strings.TYPE}}:</strong> {{product.type}}</p>
        <p *ngIf="product.countryOfOrigin">
            <strong>{{strings.COUNTRY_OF_ORIGIN}}:</strong> {{product.countryOfOrigin}}
        </p>
        <p *ngIf="product.classification"><strong>{{strings.CLASSIFICATION}}:</strong> {{strings.CLASSIFICATIONS[product.classification]}}</p>
        <p *ngIf="product.rating"><strong>{{strings.RATING}}:</strong> {{product.rating}}<img src="../../assets/icons/ic_star.svg"> {{strings.OUT_OF_10}}</p>
        <p><strong>{{strings.PRICE}}:</strong> {{product.price}} {{strings.SAUDI_RIYAL}} <span *ngIf="product.priceBeforeOffer">{{strings.INSTEAD}} {{product.priceBeforeOffer}} {{strings.SAUDI_RIYAL}}</span></p>
        <div *ngIf="product.description">
            <p><strong>{{strings.DESCRIPTION}}:</strong></p>
            <p>{{product.description}}</p>
        </div>
        <div id="actions">
            <button class="action-btn" (click)="chooseProduct()">{{strings.CHOOSE[productType]}}</button>
            <select (input)="selectCompanionById($event.target.value)">
                <option selected disabled>{{strings.CHOOSE_COMPANION[productType]}}</option>
                <option *ngFor="let companion of companions; index as i;" [value]="companion.id">{{companion.name || companion.code}}</option>
            </select>
            <div *ngIf="product.availableColors || companion?.availableColors">
                <p><strong>{{product.availableColors ? strings.AVAILABLE_COLORS : strings.CLOTH_AVAILABLE_COLORS}}: </strong></p>
                <div class="colors">
                    <span *ngFor="let color of (product.availableColors || companion?.availableColors); index as i;" class="color" [style.backgroundColor]="color" [title]="color" (click)="selectedColor = color">
                        <span *ngIf="selectedColor !== color">&nbsp;</span>
                        <span *ngIf="selectedColor === color">✔</span>
                    </span>
                </div>
            </div>
            <div>
                <p><strong>{{strings.CHOOSE_PERSON}}:</strong></p>
                <select *ngIf="state.user?.people?.length" (input)="selectPersonById($event.target.value)">
                    <option selected disabled></option>
                    <option *ngFor="let person of state.user.people; index as i;" [value]="person.id">
                        {{strings.RELATIONSHIPS[person.relationship]}} - {{person.name}}
                    </option>
                </select>
            </div>
            <div id="add-to-cart">
                <div>
                    <button (click)="quantity=quantity > 1 ? quantity-1 : quantity">-</button>
                    <span>{{quantity}}</span>
                    <button (click)="quantity=quantity+1">+</button>
                </div>
                <button class="action-btn" (click)="addToCart()" [disabled]="!state.user?.people?.length || !companion || !person">{{strings.ADD_TO_CART}} <img src="../../assets/icons/ic_cart_white.svg"></button>
            </div>
        </div>
        <div id="cards">
            <div class="card">
                <div class="header">
                    <img src="../../assets/icons/ic_delivery_truck.svg">
                    <p class="title">{{strings.FREE_DELIVERY_TITLE}}</p>
                </div>
                <p class="description">{{strings.FREE_DELIVERY_DESC}}</p>
            </div>
            <div class="card">
                <div class="header">
                    <img src="../../assets/icons/ic_clock.svg">
                    <p class="title">{{strings.FAST_DELIVERY_TITLE}}</p>
                </div>
                <p class="description">{{strings.FAST_DELIVERY_DESC}}</p>
            </div>
            <div class="card">
                <div class="header">
                    <img src="../../assets/icons/ic_cash_pay.svg">
                    <p>{{strings.PAY_ON_DELIVERY_TITLE}}</p>
                </div>
                <p class="description">{{strings.PAY_ON_DELIVERY_DESC}}</p>
            </div>
        </div>
    </div>
</div>