import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { APIService } from '../services/apiService/api.service';
import { StateService } from '../services/stateService/state.service';
import { StringsService } from '../services/stringsService/strings.service';

import OrderItem from '../../../../common/dataType/orderItem'
import { AlertService } from '../services/alertService/alert.service';

@Component({
  selector: 'app-fixed-product',
  templateUrl: './fixed-product.component.html',
  styleUrls: ['./fixed-product.component.css']
})
export class FixedProductComponent implements OnInit {
  productType
  productId
  isLoading = true
  isError = false
  product
  quantity = 1
  selectedColor

  constructor(
    public strings: StringsService,
    private state: StateService,
    private route: ActivatedRoute,
    private api: APIService,
    private alert: AlertService
  ) { }

  ngOnInit() {
    this.route.paramMap.subscribe(async paramMap => {
      this.productId = paramMap.get('id')
      this.productType = this.route.snapshot.data.productType
      await this.loadProduct()
      this.isLoading = false
    })
  }

  async loadProduct() {
    if (this.state[this.productType].some(p => p.id === this.productId)) {
      this.product = this.state[this.productType].find(p => p.id === this.productId)
    } else {
      const { isRequestSuccessful, item } = await this.api.getItem(this.productType, this.productId)
      if (!isRequestSuccessful) this.isError = true
      else this.product = item
    }
    this.selectedColor = this.product.availableColors?.length ? this.product.availableColors[0] : null
  }

  addToCart() {
    const newItem = new OrderItem(this.productType, this.quantity, this.product, this.selectedColor)
    if (this.state.cart.some(i => i.hasTheSameItemOf(newItem)))
      this.state.cart.find(i => i.hasTheSameItemOf(newItem)).quantity += newItem.quantity
    else this.state.cart.push(newItem)
    this.alert.info(this.strings.ADDED_TO_CART)
  }
}
