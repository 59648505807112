import { Component, ElementRef, ViewChild, AfterViewInit, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { AlertService } from '../services/alertService/alert.service';
import { APIService } from '../services/apiService/api.service';
import { AuthService } from '../services/authService/auth.service';
import { IdService } from '../services/idService/id.service';
import { StateService } from '../services/stateService/state.service';
import { StringsService } from '../services/stringsService/strings.service';

declare const paypal

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.css']
})
export class CartComponent implements AfterViewInit {
  isDeliveryToYanbo3 = true
  deliveryLocation = ""
  lastTotalPrice
  isViewInitialized = false
  sarToUSDConversionFactor = 0.27
  isLoading

  @ViewChild('paypal') paypalElement: ElementRef

  constructor(
    public state: StateService,
    public strings: StringsService,
    private alert: AlertService,
    private router: Router,
    private id: IdService,
    private api: APIService,
    private ngZone: NgZone
  ) { }

  ngAfterViewInit() {
    this.isViewInitialized = Boolean(this.paypalElement)
  }

  getItemPrice(item) {
    return (item.itemA?.price || 0) + (item.itemB?.price || 0)
  }

  getItemTotalPrice(item) {
    return this.getItemPrice(item) * (item.quantity || 0)
  }

  deleteItem(i) {
    this.state.cart.splice(i, 1)
  }

  get deliveryPrice() {
    if (!this.isDeliveryToYanbo3) return 100
    const totalItemsPrice = this.state.cart.reduce((a, c) => a + this.getItemTotalPrice(c), 0)
    return totalItemsPrice >= 500 ? 0 : 30
  }

  get handPrice() {
    if (!this.state.cart.some(i => i.type === 'dynamic')) return 0
    const totalItemsPrice = this.state.cart.reduce((a, c) => a + this.getItemTotalPrice(c), 0)
    return totalItemsPrice >= 600 ? 0 : 30
  }

  get noDynamic() {
    return !this.state.cart.some(i => i.type === 'dynamic')
  }

  get totalPrice() {
    const totalItemsPrice = this.state.cart.reduce((a, c) => a + this.getItemTotalPrice(c), 0)
    const newTotalPrice = totalItemsPrice + this.deliveryPrice + this.handPrice
    if (newTotalPrice !== this.lastTotalPrice) this.initConfig()
    this.lastTotalPrice = this.isViewInitialized ? newTotalPrice : this.lastTotalPrice
    return newTotalPrice
  }

  get totalPriceUsd() {
    const rawPriceUSD = this.totalPrice * this.sarToUSDConversionFactor
    return Math.ceil(rawPriceUSD * 100) / 100
  }

  get deliveryTime() {
    return this.noDynamic ? this.state.deliveryTime.fixed : this.state.deliveryTime.dynamic
  }

  private initConfig() {
    if (!this.isViewInitialized) return
    paypal.Buttons.instances.forEach(instance => instance.close())
    paypal
      .Buttons({
        createOrder: (data, actions) => {
          return actions.order.create({
            purchase_units: [{
              amount: {
                currency_code: 'USD',
                value: this.totalPriceUsd
              }
            }]
          })
        },
        onClick: () => {
          this.isLoading = true
        },
        onApprove: async (data, actions) => {
          const items = JSON.parse(JSON.stringify(this.state.cart))
          const transaction = await actions.order.capture()
          const orderDate = new Date(transaction.create_time)
          const deliveryDate = new Date(orderDate)
          deliveryDate.setDate(deliveryDate.getDate() + this.deliveryTime)
          const id = this.id.generateItemId('orders')
          const order = {
            id,
            items,
            orderDate: orderDate.getTime(),
            deliveryDate: deliveryDate.getTime(),
            isDelivered: false,
            totalPrice: this.totalPrice
          }
          this.state.user.orders = !this.state.user.orders ? [] : this.state.user.orders
          this.state.user.orders.push(order)
          await this.api.putItem('users', this.state.user.id, this.state.user)
          this.alert.success(this.strings.PAYMENT_SUCCESS)
          this.state.cart = []
          this.ngZone.run(() => this.router.navigate(['my-orders']))
        },
        onCancel: (data, actions) => {
          this.alert.warning(this.strings.PAYMENT_CANCEL)
          this.isLoading = false
        },
        onError: error => {
          this.alert.error(this.strings.PAYMENT_ERROR)
          this.isLoading = false
        }
      })
      .render(this.paypalElement.nativeElement)
  }
}
