import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StateService } from '../services/stateService/state.service';
import { StringsService } from '../services/stringsService/strings.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor(public strings: StringsService, public state: StateService, public router: Router) { }

  async ngOnInit() {}
}
