import { Component } from '@angular/core';
import { StateService } from '../services/stateService/state.service';
import { StringsService } from '../services/stringsService/strings.service';

@Component({
  selector: 'app-my-orders',
  templateUrl: './my-orders.component.html',
  styleUrls: ['./my-orders.component.css']
})
export class MyOrdersComponent {

  constructor(public state: StateService, public strings: StringsService) { }

  get orders() {
    this.state.user?.orders?.sort((a, b) => b.orderDate - a.orderDate)
    return this.state.user?.orders
  }

  getItemPrice(item) {
    return (item.itemA?.price || 0) + (item.itemB?.price || 0)
  }

  getItemTotalPrice(item) {
    return this.getItemPrice(item) * (item.quantity || 0)
  }

  arabicDate(mls) {
    const date = new Date(mls)
    return date.toLocaleDateString('ar', { weekday: 'long', year: 'numeric', month: 'numeric', day: 'numeric' })
  }
}
