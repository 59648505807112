<div id="loading" *ngIf="isLoading">
    <p>{{strings.LOADING}}</p>
</div>

<div id="gallery" *ngIf="!isLoading">
    <div id="filters">
        <div class="filter">
            <label>{{strings.PRICE}}: </label>
            <div id="price-filters">
                <input id="min-price" type="number" min="0" [placeholder]="strings.MIN_PRICE" required (input)="minPrice=round($event.target.value)">
                <input id="max-price" type="number" min="0" [placeholder]="strings.MAX_PRICE" required (input)="maxPrice=round($event.target.value)">
            </div>
        </div>
        <div class="filter" *ngIf="['cloths', 'unclassifiedProducts'].includes(productType)">
            <label>{{strings.COLOR}}: </label>
            <div id="color-filters">
                <label class="color-filter" *ngFor="let color of colors; index as i;">
                    <input class="toggle" type="checkbox" (change)="toggleColor(color)">
                    <div class="color-checkbox" [style.backgroundColor]="color"></div>
                </label>
            </div>
        </div>
    </div>
    <div id="product-grid">
        <app-product-card *ngFor="let product of filteredProducts; index as i;" [product]="product" (click)="router.navigateByUrl('/' + productType + '/' + product.id)"></app-product-card>
    </div>
</div>