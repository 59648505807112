import { Injectable } from '@angular/core';
import firebase from '../firebaseService/firebase.service'

@Injectable({
  providedIn: 'root'
})
export class IdService {
  private db = firebase.firestore()

  constructor() { }

  generateItemId(itemType: string) {
    return this.db.collection(itemType).doc().id
  }
}
