export default class OrderItem {
    constructor(
        public type: string,
        public quantity: number,
        public itemA: any,
        public color?: string,
        public itemB?: any,
        public person?: any
    ){}
    hasTheSameItemOf(o: OrderItem): boolean {
        const checks = [
            this.type === o.type,
            this.itemA.id === o.itemA.id,
            this.itemB?.id === o.itemB?.id,
            this.color === o.color,
            this.person?.id === o.person?.id
        ]
        return checks.every(c => c)
    }
}