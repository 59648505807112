import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AlertService {
  alerts = []

  constructor() { }

  error(msg: string) {
    this.alerts.unshift(new Alert(`${Date.now()}`, 'error', msg))
  }

  success(msg: string) {
    this.alerts.unshift(new Alert(`${Date.now()}`, 'success', msg))
  }

  info(msg: string) {
    this.alerts.unshift(new Alert(`${Date.now()}`, 'info', msg))
  }

  warning(msg: string) {
    this.alerts.unshift(new Alert(`${Date.now()}`, 'warning', msg))
  }

  close(id: string) {
    this.alerts = this.alerts.filter(alert => alert.id !== id)
  }
}

class Alert {
  constructor(public id: string, public clazz: string, public msg: string) { }
}
