import { Injectable } from '@angular/core';
import OrderItem from '../../../../../common/dataType/orderItem';
import { APIService } from '../apiService/api.service';

@Injectable({
  providedIn: 'root'
})
export class StateService {
  cloths = []
  accessories = []
  models = []
  unclassifiedProducts = []
  items = []
  user
  cart: OrderItem[] = []
  selectedCloths = []
  selectedModels = []
  deliveryTime: any = {}

  constructor(private api: APIService) {
    this.requestItems()
    this.requestDeliveryTime()
  }

  async requestItems() {
    const collections = ['cloths', 'accessories', 'models']
    const requests = {}
    for (const collection of collections) {
      requests[collection] = this.api.getCollection(collection)
    }
    for (const collection in requests) {
      if (requests.hasOwnProperty(collection)) {
        this[collection] = this.shuffle((await requests[collection]).items)
      }
    }
    this.items = this.shuffle(this.cloths.map(i => { i.productType = 'cloths'; return i })
      .concat(this.accessories.map(i => { i.productType = 'accessories'; return i }))
      .concat(this.models.map(i => { i.productType = 'models'; return i }))
      .filter(item => item.priceBeforeOffer > item.price)
    )
  }

  async requestDeliveryTime() {
    const { deliveryTime } = await this.api.getDeliveryTime()
    this.deliveryTime.fixed = deliveryTime.fixed || 7
    this.deliveryTime.dynamic = deliveryTime.dynamic || 14
  }

  shuffle(array) {
    let counter = array.length
    const newArray = Array(counter)

    while (counter > 0) {
      const index = Math.floor(Math.random() * counter)
      counter--
      newArray[counter] = array[index]
      array[index] = array[counter]
    }

    return newArray
  }
}
