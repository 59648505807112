import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StringsService {
  ADDRESS = "ينبع - السعودية"
  PHONE = "00966 539 878 800"
  HOME = "الرئيسية"
  MODELS = "الموديلات"
  CLOTHS = "الأقمشة"
  ACCESSORIES = "الإكسسوارات"
  CONTACT_US = "تواصل معنا"
  BANNER_TEXT = "تصاميم جديدة ومميزة وعصرية من الزوايا الستة عشر"
  WHO_WE_ARE = "من نحن"
  WHO_WE_ARE_DISC = "يمكنكم ويشرفنا أن تتعرفوا علينا أكثر من خلال التواصل معنا"
  WHO_WE_ARE_CARD_DISC = "الجودة والإتقان ، بأنسب الأثمان"
  OUR_VIEW = "رؤيتنا"
  OUR_VIEW_CARD_DISC = "منتجات فاخرة، بجودة ماهرة، ولمسات ساحرة، وأسعار باهرة، ومنافسة قاهرة"
  OUR_PLAN = "خطتنا"
  OUR_PLAN_CARD_DISC = "إرضاء عملاءنا الكرام، بالسعر والجودة والإهتمام"
  OUR_MISSION = "مهمتنا"
  OUR_MODELS = "موديلاتنا"
  OUR_MODELS_DISC = "يوجد لدينا موديلات مميزة وراقية جداً تليق بكم، يشرفنا التعرف على موديلاتنا العصرية"
  THIYAB = "ثياب"
  SEDIRI = "سديري"
  DA5ILI = "داخلية"
  SHIMA8 = "أشممغة"
  AGE_GROUPS = "الفئات العمرية"
  MEN = "رجالي"
  YOUTH = "شبابي"
  KIDS = "ولادي"
  OUR_CLOTHS = "أقمشتنا"
  SAUDI_RIYAL = "ر.س"
  WHO_WE_ARE_FOOTER = "لمسات ساحرة،\nجودة ماهرة،\nأسعار باهرة..."
  IMPORTANT_LINKS = "روابط مهمة"
  FACEBOOK = "فيسبوك"
  INSTAGRAM = "إنستغرام"
  OUR_OFFERS = "عروضنا"
  PHONE_NUMBER = "رقم الهاتف"
  SIGN_WITH_US = "سجل معنا"
  SIGN = "تسجيل"
  CONFIRM = "تأكيد"
  ERROR_SMS_NOT_SENT = "حدث خطأ، لم يتم إرسال رسالة الSMS."
  CODE_SENT = "يرجى إدخال الكود الذي تم إرساله إلى رقم الموبايل:"
  ERROR_SIGN_IN = "لم يتم إيجاد الحساب."
  ERROR_CODE = "الكود الذي أدخلته غير صحيح أو منتهي الصلاحية."
  SIGN_IN_SUCCESS = "تم تسجيل الدخول."
  NAME = "الإسم"
  CODE = "الكود"
  TYPE = "النوع"
  DESCRIPTION = "الوصف"
  COUNTRY_OF_ORIGIN = "بلد المنشأ"
  CLASSIFICATION = "التصنيف"
  CLASSIFICATIONS = {
    REGULAR: "ثوب عادي",
    OCCASION: "حفلات ومناسبات"
  }
  INSTEAD = "بدل"
  SIZE = "الحجم"
  RATING = "التقييم"
  AVAILABLE_COLORS = "الألوان المتوفرة"
  CLOTH_AVAILABLE_COLORS = "الألوان المتوفرة من القماش المختار"
  SIZES = "المقاسات"
  INCH = "إنش"
  CM = "سنتيمتر"
  AGE = "العمر"
  HEIGHT = "الطول"
  RELATIONSHIP = "العلاقة"
  RELATIONSHIPS = {
    OWNER: "أنا",
    SON: "إبني",
    FATHER: "أبي",
    BROTHER: "أخي",
  }
  ENTER_NAME = {
    OWNER: "أدخل إسمك",
    SON: "أدخل إسم إبنك",
    FATHER: "أدخل إسم أبوك",
    BROTHER: "أدخل إسم أخوك",
  }
  SLEEVE_LENGTH = "طول الكم"
  SHOULDER_LENGTH = "مقاس الكتف"
  CHEST_LENGTH = "مقاس الصدر"
  NECK_LENGTH = "مقاس الرقبة"
  HAND_SIZE = "وسع اليد"
  KABAK_LENGTH = "طول الكبك"
  DOWN_LENGTH = "العرض السفلي"
  SAVE = "حفظ المقاسات"
  ERROR_INVALID_INPUT = "المعلومات اللتي أدخلتها ناقصة أو تحتوي على خطأ."
  SIGN_OUT = "تسجيل الخروج"
  VIEW_PEOPLE = "المقاسات"
  MUST_HAVE_ONE_PERSON = "يجب أن يكون عدد الأشخاص على الأقل 1."
  NEW_PERSON = "إضافة شخص"
  NEW_SIZE_SUCCESS = "تم تحديث القياسات بنجاح"
  MORE = "المزيد"
  DELETE = "حذف"
  IN_INCH = "بالإنش"
  IN_CM = "بالسنتيمتر"
  OTHER_PRODUCTS = "أخرى"
  REQUEST_ERROR = "حدث خطأ أثناء الإتصال بالسيرفر."
  LOADING = "جاري التحميل..."
  PRICE = "السعر"
  MIN_PRICE = "أقل سعر"
  MAX_PRICE = "أعلى سعر"
  COLOR = "اللون"
  ADD_TO_CART = "إضافة إلى السلة"
  FREE_DELIVERY_TITLE = "شحن مجاني"
  FREE_DELIVERY_DESC = "للطلبات فوق 500 ريال داخل مدينة ينبع من الزوايا الستة عشر"
  FAST_DELIVERY_TITLE = "توصيل سريع"
  FAST_DELIVERY_DESC = "إنجاز العمل بإتقان وحسب المواعيد"
  PAY_ON_DELIVERY_TITLE = "طرق دفع مختلفة"
  PAY_ON_DELIVERY_DESC = "نوفر لك طرق مختلفة ومتعددة للدفع"
  ADDED_TO_CART = "تمت إضافة السلعة للسلة."
  CHOOSE_COMPANION = {
    models: "إختار نوع القماش",
    cloths: "إختار الموديل"
  }
  CHOOSE = {
    models: "إضافة إلى الموديلات المختارة",
    cloths: "إضافة إلى الأقمشة المختارة"
  }
  ADDED_TO_SELECTED = {
    models: "تمت الإضافة للموديلات المختارة.",
    cloths: "تمت الإضافة للأقمشة المختارة."
  }
  CHOOSE_PERSON = "الموديل ل"
  EMPTY_CART = "لا يوجد منتجات في سلة التسوق"
  PRODUCT = "السلعة"
  QUANTITY = "الكمية"
  TOTAL = "الإجمالي"
  YANBO3 = "ينبع"
  OTHER = "غير ذلك"
  DELIVERY_LOCATION = "توصيل إلى"
  ENTER_DELIVERY_LOCATION = "أدخل مكان التوصيل"
  OVERVIEW = "ملخص"
  DELIVERY_PRICE = "سعر التوصيل"
  HAND_PRICE = "شغل اليد"
  FREE = "مجاني"
  TOTAL_PRICE = "إجمالي الطلب"
  PAYMENT_SUCCESS = "تم الدفع بنجاح."
  PAYMENT_CANCEL = "لم يتم الدفع."
  PAYMENT_ERROR = "حدث خطأ أثناء محاولة الدفع."
  ERROR_EDIT_ORDER_WHILE_PAYING = "لا يمكنك تعديل الطلب أثناء عملية الدفع."
  DELIVERY_IN = "سيتم التوصيل خلال"
  DAYS = "أيام"
  DAY = "يوم"
  NO_ORDERS = "لم تقم بأي طلبات."
  MY_ORDERS = "طلباتي"
  ORDER = "الطلب "
  ORDER_DATE = "تاريخ الطلب"
  DELIVERY_DATE = "تاريخ التوصيل"
  ITEMS = "تفاصيل السلع"
  ORDER_STATUS = "حالة الطلب"
  PENDING = "جاري العمل على الطلب"
  DELIVERED = "تم تسليم الطلب"
  OUT_OF_10 = "من 10"

  constructor() { }
}