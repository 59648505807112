<section id="banner">
    <img src="../../assets/main-banner.svg">
    <p>{{strings.BANNER_TEXT}}</p>
</section>

<section id="who-we-are">
    <div class="underlined-text">
        <h2>{{strings.WHO_WE_ARE}}</h2>
        <hr>
    </div>
    <p>{{strings.WHO_WE_ARE_DISC}}</p>
    <div class="cards-container">
        <div class="card">
            <div><img src="../../assets/icons/ic_list.svg"></div>
            <div class="underlined-text">
                <p>{{strings.OUR_MISSION}}</p>
                <hr>
            </div>
            <p>{{strings.WHO_WE_ARE_CARD_DISC}}</p>
        </div>
        <div class="card">
            <div><img src="../../assets/icons/ic_financial_bars.svg"></div>
            <div class="underlined-text">
                <p>{{strings.OUR_PLAN}}</p>
                <hr>
            </div>
            <p>{{strings.OUR_PLAN_CARD_DISC}}</p>
        </div>
        <div class="card">
            <div><img src="../../assets/icons/ic_eye.svg"></div>
            <div class="underlined-text">
                <p>{{strings.OUR_VIEW}}</p>
                <hr>
            </div>
            <p>{{strings.OUR_VIEW_CARD_DISC}}</p>
        </div>
    </div>
</section>

<section id="our-models">
    <div class="underlined-text">
        <h2>{{strings.OUR_MODELS}}</h2>
        <hr>
    </div>
    <p>{{strings.OUR_MODELS_DISC}}</p>
    <div class="cards-container">
        <div class="card">
            <img src="../../assets/clothes/thiyab.png">
            <div class="background-blur">
                <p>{{strings.THIYAB}}</p>
            </div>
        </div>
        <div class="card">
            <img src="../../assets/clothes/shima8.png">
            <div class="background-blur">
                <p>{{strings.SHIMA8}}</p>
            </div>
        </div>
        <div class="card">
            <img src="../../assets/clothes/sediri.png">
            <div class="background-blur">
                <p>{{strings.SEDIRI}}</p>
            </div>
        </div>
        <div class="card">
            <img src="../../assets/clothes/da5ili.png">
            <div class="background-blur">
                <p>{{strings.DA5ILI}}</p>
            </div>
        </div>
    </div>
</section>

<section id="age-groups">
    <div class="underlined-text">
        <h2>{{strings.AGE_GROUPS}}</h2>
        <hr>
    </div>
    <div class="cards-container">
        <div class="card">
            <img src="../../assets/age/men.svg">
            <div class="background-blur">
                <p>{{strings.MEN}}</p>
            </div>
        </div>
        <div class="card">
            <img src="../../assets/age/youth.svg">
            <div class="background-blur">
                <p>{{strings.YOUTH}}</p>
            </div>
        </div>
        <div class="card">
            <img src="../../assets/age/kids.svg">
            <div class="background-blur">
                <p>{{strings.KIDS}}</p>
            </div>
        </div>
    </div>
</section>

<section id="our-cloths" *ngIf="state.cloths.length">
    <div class="underlined-text">
        <h2>{{strings.OUR_CLOTHS}}</h2>
        <hr>
    </div>
    <div class="cards-container">
        <app-product-card *ngFor="let cloth of state.cloths.slice(0, 4); index as i;" [product]="cloth" (click)="router.navigateByUrl('/cloths/' + cloth.id)"></app-product-card>
    </div>
</section>

<section id="our-offers" *ngIf="state.items.length">
    <div class="underlined-text">
        <h2>{{strings.OUR_OFFERS}}</h2>
        <hr>
    </div>
    <div class="cards-container">
        <app-product-card *ngFor="let item of state.items.slice(0, 4); index as i;" [product]="item" (click)="router.navigateByUrl('/' + item.productType + '/' + item.id)"></app-product-card>
    </div>
</section>